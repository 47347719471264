<template>
  <div class="grid">
    <div class="col-12 lg:col-4">
      <Card>
        <template #title>Создание отчёта в ОКБ</template>
        <template #content>
          <Button label="Создать отчёт" :loading="reportCreating" @click="createReport"></Button>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import ReportService from "@/service/ReportService";

export default {
  name: "OkbReport",
  data() {
    return {
      reportCreating: false,
    }
  },
  methods: {
    createReport() {
      this.$confirm.require({
        header: "Подтвердите создание отчёта в ОКБ",
        message: "Отчёт в ОКБ будет включать в себя все события по закрытым отправленным в НБКИ займам, которые ещё не были отправлены в ОКБ.",
        acceptIcon: 'pi pi-plus',
        acceptLabel: 'Создать',
        rejectIcon: 'pi pi-ban',
        rejectLabel: 'Отмена',
        accept: () => {
          this.reportCreating = true
          ReportService.createOkbReport().then((res) => {
            if (res.error !== null) {
              this.$toast.add({
                severity: 'error',
                summary: 'Ошибка создания отчёта в ОКБ',
                detail: res.error,
                life: 4000
              })
              this.reportCreating = false
              return
            }
            this.$toast.add({
              severity: 'success',
              summary: 'Отчёт в ОКБ создан',
              detail: 'Создан отчёт в ОКБ с идентификатором ' + res.report.id,
              life: 4000
            })
            this.$router.push('/report/' + res.report.id)
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>